import React, { useState } from 'react';
import { Container, Button, Row, Col, Alert } from 'react-bootstrap';
import { useComedor } from './ComedorContext';
import "./styles/PedidoConfirmado.css";
import img from '../imgs/favicon_black.svg';
import img2 from '../imgs/LOGOTIPO_AZUL.svg';
import { useNavigate } from 'react-router-dom';

const PedidoConfirmado = () => {
  const navigate = useNavigate();
  const { pedidoConfirmado, clienteSeleccionado,
    productoSeleccionado, cantidadSeleccionada,
    setProductoSeleccionado, setClienteSeleccionado,
    setCantidadSeleccionada, setPedidoConfirmado } = useComedor();
  const numeroPedido = pedidoConfirmado ? pedidoConfirmado.code.toString().padStart(11, '0') : '';

  const [isCouponPrinted, setIsCouponPrinted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const realizarOtroPedido = () => {
    if (!isCouponPrinted) {
      setShowAlert(true);
      return;
    }
    setProductoSeleccionado(null);
    setClienteSeleccionado(null);
    setCantidadSeleccionada(1);
    setPedidoConfirmado(null);
    navigate('/pedido');
  };

  const handlePrint = async () => {
    try {
      // Crear el canvas con ancho y alto múltiplo de 8, ajustado al doble de tamaño
      const canvasWidth = 800; // Múltiplo de 8
      const canvasHeight = 1600; // Múltiplo de 8
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      // Fondo blanco
      ctx.fillStyle = "#FFFFFF";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Cargar y dibujar el logo con tamaño mayor y más separado del número
      const logo = new Image();
      logo.src = img2;
      logo.onload = () => {
        ctx.drawImage(logo, (canvas.width - 500) / 2, 60, 500, 350); // Tamaño del logo al 500x350

        // Dibujar el número de pedido con tamaño de fuente más grande y más abajo
        ctx.fillStyle = "#000000";
        ctx.font = "bold 116px Arial"; // Tamaño de fuente ajustado
        ctx.textAlign = "center";
        ctx.fillText(`#${numeroPedido}`, canvas.width / 2, 650); // Posición ajustada

        // Dibujar los datos del cliente y del pedido, con tamaño de fuente más grande
        ctx.font = "90px Arial"; // Tamaño de fuente ajustado
        ctx.fillText(`Nombre: ${clienteSeleccionado.first_name} ${clienteSeleccionado.last_name}`, canvas.width / 2, 800); // Posición ajustada
        ctx.fillText(`Pedido: ${productoSeleccionado.name}`, canvas.width / 2, 900); // Posición ajustada
        ctx.fillText(`Cantidad: ${cantidadSeleccionada}`, canvas.width / 2, 1000); // Posición ajustada

        // Convertir el canvas a una imagen en formato Base64 con el prefijo completo
        const imageBase64 = canvas.toDataURL('image/png'); // Mantiene el prefijo `data:image/png;base64,`

        // Crear un enlace rawbt para la impresión directa con el prefijo completo
        const rawbtLink = `rawbt:${imageBase64}`;

        // Crear un elemento <a> y simular un click para imprimir
        const linkElement = document.createElement('a');
        linkElement.href = rawbtLink;
        linkElement.style.display = 'none';
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);

        // Marcar que el cupón fue impreso
        setIsCouponPrinted(true);
        setShowAlert(false);
      };
    } catch (error) {
      console.error('Error:', error);
      alert('Hubo un problema al generar el cupón.');
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Row className="w-100 justify-content-center align-items-center">
        <Col md={10} className="d-flex flex-column align-items-center">
          <div className="rounded-div p-4 shadow bg-white" style={{ width: '95%', height: '580px', color: 'black', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <h3 style={{ marginBottom: '20px' }} className='text-saigro'>¡Muchas gracias por elegirnos!</h3>
            <p style={{ marginBottom: '20px', fontSize: '24px', color: 'black' }}>
              Tu cupón de pedido es:
            </p>
            <div className="print-section border rounded p-2" style={{ minWidth: "350px", minHeight: "150px" }}>
              <div className="d-flex justify-content-center">
                <img src={img2} alt="Logo" className="logo-img mb-2 mt-3" width={120} />
              </div>
              <span style={{ display: 'block', fontWeight: '700', fontSize: '24px' }}>
                #{numeroPedido}
              </span>
              <span style={{ display: 'block', fontWeight: '500', fontSize: '12px' }}>
                Nombre y Apellido: {clienteSeleccionado.first_name} {clienteSeleccionado.last_name}
              </span>
              <span style={{ display: 'block', fontWeight: '500', fontSize: '12px' }}>
                Pedido: {productoSeleccionado.name}
              </span>
              <span style={{ display: 'block', fontWeight: '500', fontSize: '12px', marginBottom: '10px' }}>
                Cantidad: {cantidadSeleccionada}
              </span>
            </div>
          </div>
          <Button onClick={handlePrint} className="custom-continue-button mt-3 p-3" style={{ width: '100%', fontSize: "16px", fontWeight: 600 }}>Imprimir Cupón</Button>
          <Button onClick={realizarOtroPedido} className="custom-continue-button mt-4 p-3" style={{ width: '100%', marginTop: '15px', fontSize: "16px", fontWeight: 600 }}>Realizar otro pedido</Button>
          {showAlert && <Alert variant="warning">Por favor, imprime el cupón antes de realizar otro pedido.</Alert>}
        </Col>
      </Row>
    </Container>
  );
};

export default PedidoConfirmado;
