import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import { useComedor } from './ComedorContext';
import { useNavigate } from 'react-router-dom';

const LoginModal = ({ showModal, handleClose, messageTitle, dni }) => {
  const navigate = useNavigate();
  const { comedorSeleccionado } = useComedor();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    dni: dni || '',
    email: '',
    birth_date: ''
  });
  const [errors, setErrors] = useState({});

  // Sync dni prop with formData
  useEffect(() => {
    setFormData(prevFormData => ({ ...prevFormData, dni: dni || '' }));
  }, [dni]);

  useEffect(() => {
    setCompanies(comedorSeleccionado.companies);
  }, []);

  const validateForm = () => {
    let newErrors = {};
    if (!selectedCompany || selectedCompany === '0') {
      newErrors.selectedCompany = 'Debe seleccionar una compañía.';
    }
    if (!formData.first_name.trim()) {
      newErrors.first_name = 'El nombre es obligatorio.';
    }
    if (!formData.last_name.trim()) {
      newErrors.last_name = 'El apellido es obligatorio.';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'El email es obligatorio.';
    }
    if (!formData.birth_date.trim()) {
      newErrors.birth_date = 'La fecha de cumpleaños es obligatoria.';
    }
    if (!formData.dni.trim() || !/^\d{7,9}$/.test(formData.dni)) {
      newErrors.dni = 'El DNI debe tener entre 7 y 9 caracteres numéricos.';
    }
    if (formData.email.trim() && !/^\S+@\S+\.\S+$/.test(formData.email)) {
      newErrors.email = 'El formato de email no es válido.';
    }
    return newErrors;
  };

  const handleSelectedCompany = (e) => {
    setSelectedCompany(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const payload = {
      ...formData,
      company_uuid: selectedCompany,
      establishment_uuid: comedorSeleccionado.uuid
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/dinning`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });
      console.log(payload);
      if (response.ok) {
        handleClose();
        navigate('/miembro-selector');
      } else {
        console.error('Failed to register client');
        setErrors({ submit: 'No se pudo registrar el cliente.' });
      }
    } catch (error) {
      console.error('Network error:', error);
      setErrors({ submit: 'Error de red.' });
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose} centered>
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>{messageTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errors.submit && <Alert variant="danger">{errors.submit}</Alert>}
          <Form.Group>
            <Form.Label>Empresa</Form.Label>
            <Form.Control as="select" value={selectedCompany} onChange={handleSelectedCompany} isInvalid={!!errors.selectedCompany}>
              <option value="0">Seleccione una compañía</option>
              {companies.map((company) => (
                <option key={company.uuid} value={company.uuid}>{company.name}</option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.selectedCompany}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Nombre</Form.Label>
            <Form.Control type="text" name="first_name" value={formData.first_name} onChange={handleChange} isInvalid={!!errors.first_name} />
            <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Apellido</Form.Label>
            <Form.Control type="text" name="last_name" value={formData.last_name} onChange={handleChange} isInvalid={!!errors.last_name} />
            <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>DNI</Form.Label>
            <Form.Control type="number" name="dni" value={formData.dni} onChange={handleChange} isInvalid={!!errors.dni}/>
            <Form.Control.Feedback type="invalid">{errors.dni}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" name="email" value={formData.email} onChange={handleChange} isInvalid={!!errors.email} />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Fecha de Cumpleaños</Form.Label>
            <Form.Control type="date" name="birth_date" value={formData.birth_date} onChange={handleChange} isInvalid={!!errors.birth_date} />
            <Form.Control.Feedback type="invalid">{errors.birth_date}</Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Cerrar</Button>
          <Button variant="primary" type="submit">Enviar</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default LoginModal;
