import React, { useEffect, useState } from 'react';
import { Container, Dropdown, Button, Carousel, Modal, Row, Col } from 'react-bootstrap';
import { useComedor } from './ComedorContext';
import { useNavigate } from 'react-router-dom';
import './styles/PedidoSelector.css';
import desayunoImg from '../imgs/desayuno.jpeg';
import almuerzoImg from '../imgs/almuerzo.jpeg';
import cenaImg from '../imgs/cena.jpg';
import meriendaImg from '../imgs/merienda.jpg';

const PedidoSelector = () => {
  const navigate = useNavigate();
  const { comedorSeleccionado, setProductoSeleccionado, productoSeleccionado, setComedorSeleccionado } = useComedor();
  const [productos, setProductos] = useState([]);
  const [productoActual, setProductoActual] = useState('Seleccione una opción');
  const [showModal, setShowModal] = useState(false);
  const [allPhotos, setAllPhotos] = useState([]); // Aquí almacenaremos todas las fotos de todos los productos.

  // Imágenes por defecto
  const defaultImages = [
    { src: desayunoImg, alt: "Desayuno" },
    { src: almuerzoImg, alt: "Almuerzo" },
    { src: cenaImg, alt: "Cena" },
    { src: meriendaImg, alt: "Merienda" },
  ];

  useEffect(() => {
    const fetchProductos = async () => {
      const apiUrl = process.env.REACT_APP_API_URL + `/api/establishments/products/dinning/${comedorSeleccionado.uuid}`;
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Respuesta del servidor no fue exitosa');
        }
        const data = await response.json();
        setProductos(data.products);

        // Recolectar todas las fotos de todos los productos
        const fotos = data.products.flatMap(producto => producto.photos.map(photo => photo.blob));
        setAllPhotos(fotos); // Guardamos todas las fotos en el estado
      } catch (error) {
        console.error('Error al obtener los productos:', error);
      }
    };

    if (comedorSeleccionado) {
      fetchProductos();
    }
  }, [comedorSeleccionado]);

  useEffect(() => {
    if (productoSeleccionado) {
      setProductoActual(productoSeleccionado.name);
    }
  }, [productoSeleccionado]);

  const handleSelectProducto = (eventKey) => {
    const producto = productos.find(p => p.uuid === eventKey);
    if (producto) {
      setProductoActual(producto.name);
      setProductoSeleccionado(producto);
    }
  };

  const handleContinue = () => {
    if (!productoSeleccionado || productoActual === 'Seleccione una opción') {
      setShowModal(true);
    } else {
      navigate('/miembro-selector');
    }
  };

  const handleCloseModal = () => setShowModal(false);

  const goBack = () => {
    setComedorSeleccionado(null)
    navigate('/');
  };

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Row className="w-100 justify-content-center align-items-center">
        <Col md={10} className="d-flex flex-column align-items-center">
          <div className="rounded-div p-4 shadow bg-white" style={{ width: '95%', height:'580px' }}>
            <div style={{ textAlign: "left" }}>
              <Button variant="secondary" className="custom-continue-button mb-1" onClick={goBack}>
                Atrás
              </Button>
            </div>
            <div className="text-center">
              <h2 className="text-saigro">¡Bienvenido/a!</h2>
              <hr className="text-black mb-2" />

              <Carousel className="mb-2">
                {allPhotos.length > 0 ? (
                  allPhotos.map((photo, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100 rounded"
                        src={`data:image/jpeg;base64,${photo}`}
                        alt={`Foto ${index + 1}`}
                        style={{ minWidth: '400px', maxWidth: '400px', minHeight: '250px', maxHeight: '250px', margin: '0 auto', objectFit: 'cover' }}
                      />
                    </Carousel.Item>
                  ))
                ) : (
                  // Mostrar imágenes por defecto si no hay fotos
                  defaultImages.map((item, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100 rounded"
                        src={item.src}
                        alt={item.alt}
                        style={{ minWidth: '400px', maxWidth: '400px', minHeight: '250px', maxHeight: '250px', margin: '0 auto', objectFit: 'cover' }}
                      />
                    </Carousel.Item>
                  ))
                )}
              </Carousel>

              <h4 className="mt-3 text-black">¿Qué desea pedir hoy?</h4>
              <Dropdown className="w-100 mt-3" onSelect={handleSelectProducto}>
                <Dropdown.Toggle className="custom-dropdown-toggle w-100 p-3" variant="secondary" id="pedido-dropdown" style={{ fontSize: "16px", fontWeight: 600 }}>
                  {productoActual}
                </Dropdown.Toggle>
                <Dropdown.Menu className="custom-dropdown-menu w-100">
                  {productos.map((producto) => (
                    <Dropdown.Item key={producto.uuid} eventKey={producto.uuid} style={{ fontSize: "16px", fontWeight: 600, padding: "10px" }}>
                      {producto.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              <Button variant="secondary" className="custom-continue-button mt-2 p-3" style={{ width: '100%', fontSize: "16px", fontWeight: 600 }} onClick={handleContinue}>
                Continuar
              </Button>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Selección de producto</Modal.Title>
              </Modal.Header>
              <Modal.Body>Por favor, seleccione una opción antes de continuar.</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PedidoSelector;