import React, { createContext, useContext, useState } from 'react';

const ComedorContext = createContext();

export const useComedor = () => useContext(ComedorContext);

export const ComedorProvider = ({ children }) => {
  const [comedorSeleccionado, setComedorSeleccionado] = useState(null);
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);
  const [clienteSeleccionado, setClienteSeleccionado] = useState(null);
  const [cantidadSeleccionada, setCantidadSeleccionada] = useState(1);
  const [pedidoConfirmado, setPedidoConfirmado] = useState(null);

  return (
    <ComedorContext.Provider value={{
      comedorSeleccionado, setComedorSeleccionado,
      productoSeleccionado, setProductoSeleccionado,
      clienteSeleccionado, setClienteSeleccionado,
      cantidadSeleccionada, setCantidadSeleccionada,
      pedidoConfirmado, setPedidoConfirmado
    }}>
      {children}
    </ComedorContext.Provider>
  );
};
