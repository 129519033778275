import React, { useState } from 'react';
import { Modal, Button, Container, Form, InputGroup, Spinner, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useComedor } from './ComedorContext';
import LoginModal from './LoginModal';

const MiembroSelector = () => {
  const navigate = useNavigate();

  const [dni, setDni] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [clienteCargado, setClienteCargado] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [isDniValid, setIsDniValid] = useState(true);
  const [dniErrorMessage, setDniErrorMessage] = useState('');

  const { comedorSeleccionado, setClienteSeleccionado } = useComedor();

  const handleDniChange = (e) => {
    const newValue = e.target.value;
    // Verifica si el nuevo valor es numérico y si tiene entre 7 y 9 caracteres
    if (/^\d{0,9}$/.test(newValue)) {
      setDni(newValue);
      setDniErrorMessage(''); // Limpiar el mensaje de error cuando el valor es válido
      // Verifica si tiene entre 7 y 9 caracteres para considerar válido
      if (newValue.length <= 9) {
        setIsDniValid(true);
      } else {
        setDniErrorMessage('El DNI no puede tener mas de 9 caracteres numéricos.');
        setIsDniValid(false);
      }
    } else {
      setIsDniValid(false);
    }
  };

  const handleBuscarCliente = async (e) => {
    e.preventDefault();

    if (dni.length < 7 || dni.length > 9) {
      setDniErrorMessage('El DNI debe tener entre 7 y 9 caracteres numéricos.');
      return;
    }

    setIsLoading(true);
    const url = process.env.REACT_APP_API_URL + `/api/clients/dni/${dni}/${comedorSeleccionado.uuid}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (response.ok) {
        setCliente(data);
        setClienteCargado(true);
      } else {
        throw new Error(data.message || 'Error desconocido');
      }
    } catch (error) {
      setModalMessage(error.message);
      setShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setClienteSeleccionado(cliente);
    navigate('/confirmacion-pedido');
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Row className="w-100 justify-content-center align-items-center">
        <Col md={10} className="d-flex flex-column align-items-center">
          <div className="rounded-div p-4 shadow bg-white" style={{ width: '95%', height: '580px' }}>
            <div style={{ textAlign: "left" }}>
              <Button variant="secondary" className="custom-continue-button mb-3" onClick={goBack}>
                Atrás
              </Button>
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formDni" className="mb-3 mt-4">
                <Form.Label style={{ marginBottom: '10px', color: "black" }}>Introduzca su DNI</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    inputMode="numeric"
                    placeholder="DNI"
                    value={dni}
                    onChange={handleDniChange}
                    isInvalid={!isDniValid || dniErrorMessage}
                    required
                  />
                  <Button
                    variant="outline-secondary p-3"
                    onClick={handleBuscarCliente}
                    disabled={isLoading}
                    style={{ fontSize: "16px", fontWeight: 600 }}
                  >
                    {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Buscar'}
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {dniErrorMessage || 'Por favor, ingrese entre 7 y 9 números enteros.'}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              {clienteCargado && (
                <>
                  <div className='text-black rounded border p-3'>
                    <div style={{ textAlign: 'left', fontSize: "17px", fontWeight: 500 }}>
                      <p>Compañía: <span style={{ fontWeight: 400 }}> {cliente.company}</span></p>
                      <p>DNI: <span style={{ fontWeight: 400 }}>{cliente.dni}</span></p>
                      <p>Nombre: <span style={{ fontWeight: 400 }}>{cliente.first_name}</span></p>
                      <p>Apellido: <span style={{ fontWeight: 400 }}>{cliente.last_name}</span></p>
                    </div>
                    <p style={{ fontSize: "15px", fontWeight: 500, marginTop: "40px" }}>
                      ¿Estos son tus datos? Si lo son, continua.
                    </p>
                  </div>

                  <Button variant="secondary" type="submit" className="custom-continue-button mt-3 p-3" style={{ fontSize: "16px", fontWeight: 600, width: "200px" }}>
                    Continuar
                  </Button>
                </>
              )}
            </Form>
          </div>
        </Col>
      </Row>
      <LoginModal showModal={showModal} handleClose={() => setShowModal(false)} messageTitle={modalMessage} dni={dni} />
    </Container>
  );
};

export default MiembroSelector;
