import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ComedorProvider } from './components/ComedorContext';
import ComedorSelector from './components/ComedorSelector';
import PedidoSelector from './components/PedidoSelector';
// import TipoUsuarioSelector from './components/TipoUsuarioSelector';
import MiembroSelector from './components/MiembroSelector';
import ConfirmacionPedido from './components/ConfirmacionPedido';
import PedidoConfirmado from './components/PedidoConfirmado';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <ComedorProvider>
          <Router>
            <Routes>
              <Route path="/" element={<ComedorSelector />} />
              <Route path="/pedido" element={<PedidoSelector />} />
              {/* <Route path="/tipo-usuario" element={<TipoUsuarioSelector />} /> */}
              <Route path="/miembro-selector" element={<MiembroSelector />} />
              <Route path="/confirmacion-pedido" element={<ConfirmacionPedido />} />
              <Route path="/pedido-confirmado" element={<PedidoConfirmado />} />
            </Routes>
          </Router>
        </ComedorProvider>
      </header>
    </div>
  );
}

export default App;
