import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Dropdown, Button, Modal } from 'react-bootstrap';
import { useComedor } from './ComedorContext';
import './styles/ComedorSelector.css';
import img1 from '../imgs/LOGOTIPO_AZUL.png';

const ComedorSelector = () => {
  const [comedores, setComedores] = useState([]);
  const { setComedorSeleccionado } = useComedor();
  const [dropdownTitle, setDropdownTitle] = useState("Comedores");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const fetchComedores = async () => {
      const apiUrl = process.env.REACT_APP_API_URL + '/api/establishments';
      try {
        const response = await fetch(apiUrl);
        if (!response.ok) throw new Error('Respuesta del servidor no fue exitosa');
        const data = await response.json();
        const comedoresFiltrados = data.filter(comedor => comedor.offers_dining);
        setComedores(comedoresFiltrados);
      } catch (error) {
        console.error('Error al obtener los comedores:', error);
      }
    };

    fetchComedores();
  }, []);

  const handleSelect = (comedor) => {
    setComedorSeleccionado(comedor);
    setDropdownTitle(comedor.name);
  };

  const handleContinue = () => {
    if (dropdownTitle !== "Comedores") {
      navigate('/pedido');
    } else {
      setShowModal(true);
    }
  };

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Row className="w-100 justify-content-center align-items-center">
        <Col md={10} className="d-flex flex-column align-items-center">
          <div className="shadow p-5 bg-white rounded" style={{ width: '95%', height: '580px', textAlign: 'center' }}>
            <img src={img1} alt="Logo" className="logo-img" style={{ marginBottom: '5px', width: '200px' }} />
            <h3 className="text-saigro mb-5"></h3>
            <hr className="text-black mb-5" />
            <h4 className="text-black p-4">Seleccione el comedor</h4>
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic" className="custom-dropdown-toggle p-3" style={{ fontSize: "16px", fontWeight: 600 }}>
                {dropdownTitle}
              </Dropdown.Toggle>
              <Dropdown.Menu className="custom-dropdown-menu">
                {comedores.map(comedor => (
                  <Dropdown.Item key={comedor.uuid} style={{ fontSize: "16px", fontWeight: 600, padding: "10px" }} onClick={() => handleSelect(comedor)}>
                    {comedor.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Button 
            variant="primary" 
            className="custom-continue-button p-3" 
            style={{ marginTop: '20px', fontSize: "16px", fontWeight: 600 }} 
            onClick={handleContinue}
            >
              Continuar
            </Button>
          </div>
        </Col>
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Selección Requerida</Modal.Title>
        </Modal.Header>
        <Modal.Body>Por favor, seleccione un comedor antes de continuar.</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowModal(false)}
            style={{
              backgroundColor: isHovered ? '#1A3B85' : '#122C6B',
              fontWeight: 'bold',
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ComedorSelector;
