import React, { useState } from 'react';
import { Container, Button, Form, InputGroup, Modal, Spinner, Row, Col } from 'react-bootstrap';
import { useComedor } from './ComedorContext';
import { useNavigate } from 'react-router-dom';

const ConfirmacionPedido = () => {
  const { clienteSeleccionado, productoSeleccionado, comedorSeleccionado, setCantidadSeleccionada } = useComedor();
  const [cantidad, setCantidad] = useState(1);
  const [descripcion, setDescripcion] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const { setPedidoConfirmado } = useComedor();

  const handleCantidadChange = (e) => {
    setCantidad(e.target.value);
    if (parseInt(e.target.value, 10) > 1) {
      setError('Debe ingresar ingresar una descripción.');
    } else {
      setError('');
    }
  };

  const handleDescripcionChange = (e) => {
    setDescripcion(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (cantidad > 1 && descripcion.trim() === '') {
      setError('Debe ingresar ingresar una descripción.');
      return;
    }

    setIsLoading(true);

    const pedido = {
      uuid_client: clienteSeleccionado.uuid,
      uuid_establishment: comedorSeleccionado.uuid,
      purchase_date: new Date().toISOString(),
      details: [
        {
          product_uuid: productoSeleccionado.uuid,
          quantity: parseInt(cantidad, 10),
          description: cantidad > 1 ? descripcion : null  // Descripción solo si es mayor a 1
        }
      ]
    };

    setCantidadSeleccionada(cantidad);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/orders/dinning`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(pedido),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'No se pudo completar el pedido');
      }

      setPedidoConfirmado(data);
      setIsLoading(false);
      setShowModal(false);
      navigate('/pedido-confirmado');

    } catch (error) {
      console.error('Error al realizar el pedido:', error.message);
    } finally {
      setIsLoading(false);
      setShowModal(false);
    }
  };

  const incrementarCantidad = () => {
    setCantidad(prevCantidad => Math.max(1, parseInt(prevCantidad, 10) + 1));
  };

  const decrementarCantidad = () => {
    setCantidad(prevCantidad => Math.max(1, parseInt(prevCantidad, 10) - 1));
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <Row className="w-100 justify-content-center align-items-center">
        <Col md={10} className="d-flex flex-column align-items-center">
          <div className="rounded-div p-4 shadow bg-white w-100" style={{ maxWidth: '800px' }}>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingBottom: '20px' }}>
              <Button variant="secondary" className="custom-continue-button" onClick={goBack}>
                Atrás
              </Button>
            </div>
            {clienteSeleccionado && (
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div className='text-black rounded border p-3'>
                  <h3>Confirmación del Pedido</h3>
                  <div style={{ textAlign: 'left', fontSize: "17px", fontWeight: 500, marginTop: "20px" }}>
                    <p>Comedor: <span style={{ fontWeight: 400 }}> {comedorSeleccionado.name}</span></p>
                    <p>Compañía: <span style={{ fontWeight: 400 }}> {clienteSeleccionado.company}</span></p>
                    <p>DNI: <span style={{ fontWeight: 400 }}>{clienteSeleccionado.dni}</span></p>
                    <p>Apellido y Nombre:
                      <span style={{ fontWeight: 400 }}>
                        &nbsp;{clienteSeleccionado.last_name}, {clienteSeleccionado.first_name}
                      </span>
                    </p>
                    <p>Pedido: <span style={{ fontWeight: 400 }}>{productoSeleccionado.name}</span></p>
                    <Form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
                      <InputGroup className="mb-3">
                        <InputGroup.Text id="inputGroup-sizing-default">Cantidad</InputGroup.Text>
                        <Form.Control
                          type="number"
                          value={cantidad}
                          onChange={handleCantidadChange}
                          min="1"
                          aria-label="Cantidad"
                          aria-describedby="inputGroup-sizing-default"
                        />
                        <Button variant="outline-secondary" onClick={decrementarCantidad}><span style={{ fontSize: "20px", fontWeight: 700, marginLeft: "15px", marginRight: "15px" }}>-</span></Button>
                        <Button variant="outline-secondary" onClick={incrementarCantidad}><span style={{ fontSize: "20px", fontWeight: 700, marginLeft: "15px", marginRight: "15px" }}>+</span></Button>
                      </InputGroup>

                      {cantidad > 1 && (
                        <>
                          <Form.Group controlId="formDescripcion" className="mb-3">
                            <Form.Label>¿Por qué estás pidiendo mas de uno? Explique a continuación</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={descripcion}
                              onChange={handleDescripcionChange}
                              placeholder="Ingrese en este recuadro una descripcion, como por ejemplo, los DNI de las personas a las que les estas retirando la vianda"
                              isInvalid={!!error}
                            />
                            <Form.Control.Feedback type="invalid">
                              {error}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <p style={{ color: 'red', fontWeight: 'bold' }}>
                            Este pedido podria estar sujeto a auditoría.
                          </p>
                        </>
                      )}

                      <Button variant="secondary" type="submit" className="custom-continue-button mt-3 p-3" style={{ fontSize: "16px", fontWeight: 600, width: "200px" }}>
                        Confirmar Pedido
                      </Button>
                    </Form>
                  </div>
                  <Modal show={isLoading} onHide={() => setShowModal(false)} centered>
                    <Modal.Body className="d-flex justify-content-center">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Cargando...</span>
                      </Spinner>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ConfirmacionPedido;
